export default {
    "index.loinText01": "Professional pet supplies sourcing website",
    "index.loinText02": "Worry free express delivery",
    "index.loinText03": "Global",
    "index.loinText04": "Massive pet supplies, direct to the source, multi country discount routes",
    "index.retrievePasswordTitle": "Please enter the email associated with your account",
    "index.retrievePasswordEmaiTitle": "Change Password",
    "index.home01": "Hello, we will purchase/ship Chinese goods on your behalf",
    "index.homeBanner01": "View Orders",
    "index.homeBanner02": "Order shipment",
    "index.homeBanner03": "Transit shipment",
    "index.homeBanner04": "User receiving goods",
    "index.homeBannerText01": "Browsing products or submitting website addresses to submit purchasing orders",
    "index.homeBannerText02": "The platform accepts orders and provides inspection and quality inspection services for your procurement and warehousing",
    "index.homeBannerText03": "Apply for delivery to destination, select shipping method, and pay international shipping fees",
    "index.homeBannerText04": "The goods have arrived. Wishing you a pleasant shopping experience",
    "index.goodProducts": "Good products",
    "index.sellingWell": " are selling well",
    "index.recommendation": "recommendation",
    "index.Selected": "Selected",
    "index.self-support": "self-support",
    "index.taobao": "TaoBao",
    "index.1688": "1688",
    "index.pdd": "pdd",
    "index.jindong": "JD.com",
    "index.Tmall": "Tmall",
    "index.noData": "There's no more data left",
    "index.Loading": "Loading",
    "index.number": "number",
    "index.ProductRecommendations": "recommendation",
    "index.StartingPurchase": "pieces minimum purchase",
    "index.OrderSummary": "Order Summary",
    "index.GatheringReproducing": "Gathering and reproducing",
    "index.arrivalWarehouse": "Delivery upon arrival at warehouse",
    "index.DomesticDirectMail": "Self operated direct mail",
    "index.MoreAddresses": "More addresses",
    "index.ProductInfo": "Product information",
    "index.freight": "Domestic freight",
    "index.unitPrice": "unit price",
    "index.notes": "notes",
    "index.goodsTotal": "Total price of goods",
    "index.altogether": "altogether",
    "index.IHaveReadPurchaseInstructions": "I have read and agree to the purchase instructions",
    "index.orderConfirmText": "*After the goods arrive at the warehouse, they are immediately packed and sent out without waiting for other packages to arrive at the warehouse, resulting in faster delivery.",
    "index.placeOrder": "place order",
    "index.LeaveMessage": "Leave a message",
    "index.OptionalFilling": "Optional filling",
    "index.TotalExpenses": "Total expenses",
    "index.ValueAddedServiceFee": "Value added service fee",
    "index.cashier": "Cashier",
    "index.paidText01": "Covering 202 countries or regions, supporting 25 currencies. Payment in non US dollar currencies will incur currency exchange and handling fees. Local cooperative banks can provide debit and credit cards",
    "index.paidText02": "Global Supports 25 currencies, with currency exchange and transaction fees incurred for payments in non US dollar currencies Visa, MasterCard, AMEX, JCB",
    "index.paidText03": "Supports RMB payment and requires binding to domestic bank cards in China",
    "index.residue": "residue",
    "index.paySuccess": "Order submitted successfully",
    "index.screen": "screen",
    "index.ComprehensiveSorting": "Comprehensive sorting",
    "index.salesVolume": "sales volume",
    "index.price": "price",
    "index.Subtotal": "Subtotal",
    "index.noCart": "The shopping cart is empty, go pick your favorite good products!",
    "index.SelectAll": "Select All",
    "index.AccountBalance": "Account balance",
    "index.obligation": "obligation",
    "index.beShipped": "To be shipped",
    "index.beReceived": "To be received",
    "index.myMessage": "My message",
    "index.MyFavorites": "My favorites",
    "index.ProductName/MainOrderNumber/SubOrderNumber": "Product Name/Main Order Number/Sub Order Number",
    "index.OrderStatus": "Order Status",
    "index.startTime": "Start time",
    "index.EndTime": "End time",
    "index.to": "to",
    "index.obligation": "obligation",
    "index.Audit": "Audit",
    "index.Shipped": "Shipped",
    "index.Chargeback/Cancellation": "Chargeback/Cancellation",
    "index.totalAmount": "Total amount",
    "index.operation": "operation",
    "index.OrderNumber": "Order number",
    "index.CreationTime": "Creation time",
    "index.detail": "Detail",
    "index.afterSalesDetails": "After sales details",
    "index.dontWant": "I don't want it anymore",
    "index.priceBit": "The price is a bit expensive",
    "index.styleSpecificationQuantity": "Wrong style/specification/quantity",
    "index.NoNeed": "No need for now",
    "index.other": "other",
    "index.PaymentMethod": "Payment method",
    "index.SubmissionTime": "Submission time",
    "index.logisticsMessages": "Logistics messages",
    "index.logisticsCompany": "logistics company",
    "index.LogisticsTrackingNO": "Logistics tracking number",
    "index.logisticsInfo": "Logistics information",
    "index.deliveryTime": "delivery time",
    "index.orderTracking": "Order Tracking",
    "index.TransactionSerialNumber": "Transaction serial number",
    "index.ReceivingInfo": "Receiving information",
    "index.UserNotes": "User notes",
    "index.ShippingLogistics": "Shipping logistics",
    "index.AdditionalServiceFee": "Additional service fee",
    "index.Service": "service",
    "index.SupplementaryPaymentAmount": "Supplementary payment amount",
    "index.ReasonPayment": "Reason for supplementary payment",
    "index.ApplyingServices": "Applying for services",
    "index.ApplyAfter-salesServiceText": `Reminder: Unreasonable return and exchange fee=shipping cost sent to the seller+shipping cost sent by the seller+return and exchange service fee. Shipping cost sent to the seller: Estimated shipping cost is 6-25 yuan (specific amount will be charged according to the logistics company's charging standards based on the weight, volume, and shipping distance of the product); The shipping cost sent by the seller: You will bear the return shipping cost for free shipping products, and you will bear the shipping and return shipping costs for non free shipping products. Please refer to the seller's reply for details; Return and exchange service fee: Please refer to the "5-day no reason return and exchange service rules for purchasing agents" for details`,
    "index.applicationType": "application type",
    "index.reasonApplication": "Reason for application",
    "index.returnQuantity": "Return quantity",
    "index.ApplicationDescription": "Application Description",
    "index.ReturnsRefunds": "Returns and refunds",
    "index.exchangeGoods": "exchange goods",
    "index.refundAmount": "refund amount",
    "index.afterSalesDetails": "After sales details",
    "index.AfterSalesProducts": "After sales products",
    "index.RefundOnly": "Refund only",
    "index.UploadCredentials": "Upload credentials",
    "index.ProductSpecifications": "Product Specifications",
    "index.itemPricing": "Unit price of goods",
    "index.AfterSalesInstructions": "After sales instructions",
    "index.discoveringProductPassword": "Discovering product password",
    "index.isViewDetails": "Do you want to view the details?",
    "index.ContinueShopping": "Continue shopping",
    "index.contacts": "contacts",
    "index.phone": "cell-phone number",
    "index.country": "country",
    "index.Province/Province": "Province/State",
    "index.city": "city",
    "index.detailAddress": "Detailed Address 1",
    "index.detailAddress2": "Detailed Address 2",
    "index.zipCode": "zip code",
    "index.location": "Location",
    "index.PleaseSelect": "Please select",
    "index.ModifyAddress": "Modify Address",
    "index.newAddress": "New Address",
    "index.productNameSearch": "Product name search",
    "index.date": "date",
    "index.isNoData": "There is currently no data available",
    "index.PostTransactionAmount": "Post transaction amount",
    "index.TransactionTime": "Transaction time",
    "index.TransactionNotes": "Transaction notes",
    "index.nickname": "nickname",
    "index.Gender": "Gender",
    "index.BirthDate": "Birth date",
    "index.WeChatAccount": "WeChat account",
    "index.ContactPhoneNumber": "phone number",
    "index.E-mail": "E-mail",
    "index.ModifyAvatar": "Modify avatar",
    "index.EditInfo": "Edit Information",
    "index.userInfo": "personal information",
    "index.Language": "language",
    "index.ChangePassword": "Change password",
    "index.ClearCache": "Clear cache",
    "index.personalInfo": "personal information",
    "index.OldPassword": "Old password",
    "index.NewPassword": "New password",
    "index.transactionNo": "transaction number",
    "index.Currency": "Currency",
    "index.headText": "We use cookies to give you the best possible experience on our website. For more details including how to change your cookie settings, please read our",
    "index.headTextBtn": "Cookie Policy",
    "index.New": "New",
    "index.Products": "Products",
    "index.NewProductsText": "Explore the hottest releases in the past two weeks",
    "index.goodsStock": "goods in stock",
    "index.goodsStockText": "Find products from certified low MOQ manufacturers & wholesale suppliers",
    "index.brand": "brand",
    "index.RequestQuotations(RFQ)": "Request for Quotations (RFQ)",
    "index.RequestQuotations(RFQ)Text": "Submit an RFQ in just one minute.",
    "index.RequestQuotations(RFQ)Text2": "Compare and choose the best quotation!",
    "index.GetQuotationsNow": "Get Quotations Now",
    "index.Explored": "Have You Explored?",
    "index.homeFoot01": "24hr Delivery",
    "index.In24Cities": "In 24 cities",
    "index.HappyPetParents": "Happy pet parents",
    "index.ComplimentaryVetConsult": "Complimentary vet consult",
    "index.ForEveryNewMember": "For every new member",
    "index.PetPharmacy": "Pet Pharmacy",
    "index.Exclusive": "Exclusive",
    "index.isDefaultAddress": "Is it the default address",
    "index.area": "area",
    "index.QueryLogistics": "Query logistics",
    "index.LogOut": "Log out",
    "index.total": "total",
    "index.ProductAmount": "Product amount",
    "index.enclosure": "enclosure",
    "index.name": "full name",
    "index.company": "company",
    "index.country": "country",
    "index.OutboundOrderNo": "Outbound Order Number",
    "index.surname": "surname",
    "index.ming": "name",
    "index.accountName": "account number",
    "index.order": "order",
    "index.ArticleLost": "Article lost",
    "index.productDown": "This product has been taken down",
    "index.Piece": "Piece",
    "index.PopularArticles": "Popular articles",
    "index.RecommendedArticles": "Recommended articles",
    "index.author": "author",
    "index.LatestNews": "Latest News",
    "index.StayInfo": "Stay informed about the latest developments",
    "index.news": "news",
    "index.newsText": "Enter product details such as color, size, material, and other specific requirements.",
    "index.emailAddress": "Email address",
    "index.yourCompanyEmailAddress": "Please enter your company email address",

    "menu.overview": "Home",
    "menu.productClass": "Product classification",
    "menu.FreightCalculation": "Freight calculation",
    "menu.queryLogistics": "Query logistics",
    "menu.helpCenter": "Help Center",
    "menu.aboutUs": "About Us",
    "menu.productDetail": "Product details",
    "menu.orderConfirm": "acknowledgement of order",
    "menu.orderConfirm1688": "acknowledgement of order",
    "menu.bePaid": "Payment Order",
    "menu.bePaid1688": "Payment Order",
    "menu.paySuccess": "Submitted successfully",
    "menu.search": "search",
    "menu.searchType": "search",
    "menu.user": "My",
    "menu.cart": "Shopping Cart",
    "menu.favorite": "favorite",
    "menu.indentOrder": "Purchase Order",
    "menu.selfOrder": "My order",
    "menu.personalCenter": "Personal Center",
    "menu.myAccount": "My account",
    "menu.myAddress": "My address",
    "menu.myWallet": "My Wallet",
    "menu.MyTracks": "My Tracks",
    "menu.register": "Register",
    "menu.Cat": "Cat",
    "menu.Dog": "Dog",
    "menu.articleDetails": "Article Details",
    "menu.articleList": "Article information",

    "btn.Login": "Login",
    "btn.register": "register",
    "btn.ForgotPassword": "Forgot password?",
    "btn.submit": "Submit",
    "btn.HowShop": "How to shop",
    "btn.contactUs": "Contact us",
    "btn.MyOrder": "My Order",
    "btn.LogisticsFreight": "Logistics Freight",
    "btn.RulesPolicies": "Rules and Policies",
    "btn.LogOut": "Log out and log in",
    "btn.confirm": "confirm",
    "btn.cancel": "cancel",
    "btn.more": "more",
    "btn.BUYNOW": "BUY",
    "btn.AddCart": "add to cart",
    "btn.CollectProducts": "Collect products",
    "btn.CancelFavorite": "Cancel Favorite",
    "btn.payment": "payment",
    "btn.NextStep": "Next step",
    "btn.PutAway": "Put it away",
    "btn.Balance": "Balance",
    "btn.CreditCard": "Credit card",
    "btn.WeChatPayment": "WeChat payment",
    "btn.Alipay": "Alipay",
    "btn.platform": "platform",
    "btn.confirm": "confirm",
    "btn.comprehensiveSorting": "Comprehensive sorting",
    "btn.SelfGoods": "Self operated goods",
    "btn.taobaoGoods": "Taobao products",
    "btn.1688Goods": "1688 Goods",
    "btn.purchasingAgentGoods": "Purchasing Agency Goods",
    "btn.BatchDeletion": "Batch deletion",
    "btn.ViewMore": "View more",
    "btn.whole": "whole",
    "btn.PendingPayment": "Pending payment",
    "btn.FreightBePaid": "Freight to be paid",
    "btn.Completed": "Completed",
    "btn.query": "query",
    "btn.cancellationOrder": "cancellation of order",
    "btn.after-salesService": "Apply for after-sales service",
    "btn.payment": "payment",
    "btn.MakeUpPayment": "Make up payment immediately",
    "btn.PayShippingFees": "Pay shipping fees",
    "btn.ViewLogistics": "View logistics",
    "btn.signFor": "Confirm receipt",
    "btn.CancelAfter-salesService": "Cancel after-sales service",
    "btn.NotCancelledTemporarily": "Not cancelled temporarily",
    "btn.ConfirmCancel": "Confirm Cancel",
    "btn.Detail": "Detail",
    "btn.Submit": "Submit",
    "btn.onApplyAfter-sales": "Apply for after-sales service again",
    "btn.NotViewedNow": "Not viewed for now",
    "btn.ViewNow": "View Now",
    "btn.PleaseLogIn": "Please log in",
    "btn.viewOrder": "view order",
    "btn.homepage": "homepage",
    "btn.addAddress": "Add address",
    "btn.DefaultAddress": "Default Address",
    "btn.SetDefault": "Set as default",
    "btn.BatchManagement": "Batch management",
    "btn.BatchDelete": "Batch deletion",
    "btn.FlowingRecords": "Flowing records",
    "btn.UploadAttachments": "Click to upload attachment",
    "btn.RequestQuotations": "Request for Quotations",
    "btn.PendingOrders": "Pending orders",
    "btn.PendingReview": "Pending review",
    "btn.ToBeShipped": "To be shipped",
    "btn.ToBeReceived": "To be received",
    "btn.Signed": "Signed for",
    "btn.Completed": "Completed",
    "btn.Canceled": "Canceled",
    "btn.ReviewFailed": "Review failed",
    "btn.followUp": "consulting service",
    "btn.publish": "publish",
    "btn.Approved": "Approved",
    "btn.EditPersonalInfo": "Edit personal information",
    "btn.ClickDownload": "Click to download",
    "btn.delete": "delete",
    "btn.goLogin": "Go login",
    "btn.upPage": "previous page",
    "btn.nextPage": "next page",
    "btn.StartOrder": "Start Order",
    "btn.InstantInquiry": "Instant inquiry",
    "btn.SendInquiry": "Send an inquiry immediately",
    "btn.SendCode": "Send verification code",
    "btn.RetrieveAgain": "Retrieve again",

    "message.imgCode": "Graphic verification code",
    "message.PleaseEmail": "Please enter your email address",
    "message.PleasePassword": "Please enter password",
    "message.PleaseServiceAgreementAndPrivacyPolicy": "Please check the Service Agreement and Privacy Policy",
    "message.PleaseComplete": "Please enter complete information!",
    "message.IdentifyCodeError": "Verification code error",
    "message.ConfirmPassword": "Confirm password",
    "message.isPasswordRule": "The password should consist of 6 to 20 digits, which should be letters, numbers, or symbols",
    "message.TwoPasswordsDoNotMatch": "Two passwords do not match",
    "message.OperationSuccessful": "Operation successful",
    "message.isEmailRule": "Please enter the correct email address",
    "message.prompt": "prompt",
    "message.isLogOut": "Are you sure you want to log out?",
    "message.PleaseProductLinkQuery": "Enter product link or name to query",
    "message.PleaseKeywords": "Please enter keywords",
    "message.PleaseSelectSku": "Please select a specification",
    "message.PleaseSelectAddress": "Please select an address",
    "message.PleaseCheckBox": "Please check the box",
    "message.PleaseReadAgreeServiceAgreement": "Please read and agree to the Service Agreement",
    "message.OrderCancelled": "Order cancelled",
    "message.PleaseSelectWarehouse": "Please select a warehouse",
    "message.PleaseSelectCountry": "Please select a country",
    "message.cartText01": "Proxy orders and self operated orders cannot be selected for ordering at the same time. Please place orders separately.",
    "message.cartText02": "Total price to be paid (international freight to be calculated separately)",
    "message.DataLoss": "Data loss, please contact customer service",
    "message.PleaseSelectProduct": "Please select the product",
    "message.PleaseCheckSettled": "Please check the items to be settled",
    "message.PleaseSelectCancelOrder": "Please select the reason for canceling the order",
    "message.SuccessfullySubmittedWaitingMerchant": "Successfully submitted, waiting for merchant processing",
    "message.isDeleteAddress": "Are you sure to delete this address?",
    "message.PleaseEnter": "Enter",
    "message.PleaseCorrectPhone": "Please enter the correct phone number",
    "message.emailConfirm": "Please go to your email to confirm",
    "message.EnterKeywordSearch": "Enter a keyword to search for a product",
    "message.PleaseEnterRequiremen": "Please enter your requiremen",
    "message.PleaseEnterEmail": "Please enter your email address",
    "message.isSigned": "Are you sure to sign for it?",
    "message.isCancelOrder": "Are you sure to cancel the order?",
    "message.PleaseEnterYourNeeds": "Please enter your requirements",
    "message.PleaseEnterEmail": "Please enter your email address",
    "message.PleaseEnterContent": "Please enter the content",
    "message.isEmailRule": "Please enter the correct email address",
    "message.PleaseEnterName": "Please enter your name",
    "message.PleaseEnterCompany": "Please enter company",
    "message.PleaseEnterCountry": "Please select a country",
    "message.PleaseEnterPhone": "Please enter your phone number",
    "message.resetSuccessPleaseLogin": "Password reset successful, please log in",
    "message.linkHasExpired": "The link has expired",
    "message.linkHasTimedOut": "The link has timed out",
    "message.EmailHasBeen": "Email has been sent, please check your inbox",
    "message.VerificationCode": "Verification Code",
    "message.PleaseEnterVerificationCode": "Please enter the verification code",
    "message.registeredSuccess": "registered successfully",
 }
