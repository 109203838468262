<template>
    <div v-if="data.form" class="bg_A64 color_fff head_banner po_r">
      {{ data.form&&data.form.title&&data.form.title[$store.state.locale] }} <span class="hand main_color size_18" @click="getSee( data.form&&data.form.link&&data.form.link[$store.state.locale])">{{ data.form&&data.form.btnText&&data.form.btnText[$store.state.locale] }}</span>
      <el-icon class="color_fff hand ml_40 po_a size_18" @click="close"><CircleClose /></el-icon>
    </div>
</template>
<script>
import { setToken, getToken, removeToken } from '@/utils/auth'
export default {
  data () {
    return {
      data: {}
    }
  },
  watch: {
    '$store.state.headHtml'(val) {
      this.data = this.$store.state.headHtml&&this.$store.state.headHtml[0]
    }
  },
  mounted() {
    setTimeout(() => {
      this.data = this.$store.state.headHtml&&this.$store.state.headHtml[0]
    }, 200);
  },
  methods: {
    close() {
      this.$store.state.headShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.head_banner {
  width: 100%;
  text-align: center;
  // line-height: 2em !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  .el-icon {
    top: 5px;
    right: 5px;
  }
}
@media screen and (max-width: 768px){
  .head_banner {
  	text-align: left;
    // line-height: 15px !important;
    line-height: 1.2em !important;
    padding: 20px;
  }
}
</style>