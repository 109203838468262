<template>
    <div class="height_100">
        <div id="myDiv" class="po_f head_box bg_fff z_index_100" ref="myHeight">
            <HeadBanner v-if="$store.state.headShow&&$store.state.headHtml&&$store.state.headHtml[0]" />
            <div v-if="$store.state.headHtml&&$store.state.headHtml[1]"><Header /></div>
        </div>
        <div class="width_100" :class="{'min_h': $store.state.headShow, 'min_h_169': !$store.state.headShow}">
            <div v-if="isShow" :style="`height: ${headHeight}px`"></div>
            <router-view></router-view>
        </div>
        <Footer v-if="$store.state.bottomHtml&&$store.state.bottomHtml.type" />
    </div>
</template>
<script>
import HeadBanner from '../../components/HeadBanner.vue'
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
export default {
    name: 'Home',
    components: {
      HeadBanner,
      Header,
      Footer
    },
    data () {
        return {
            headHeight: 100,
            isShow: false,
        }
    },
    watch: {
      '$store.state.windowWidth'(val) {
        this.headHeight = document.getElementById('myDiv').offsetHeight || 100
        this.$store.state.headHeight = this.headHeight
        this.isShow = true
      },
      '$store.state.headShow'(val) {
        // this.isShow = false
        
        setTimeout(()=>{
            this.headHeight = document.getElementById('myDiv').offsetHeight || 100
            this.$store.state.headHeight = this.headHeight
            console.log(this.headHeight, 'headHeight')
            this.isShow = true
        }, 500)
      },
      '$store.state.headHtml': {
        // 立即监听
        immediate: true,
        // 深度监听
        deep: true,
        handler(val){
            setTimeout(()=>{
                this.headHeight = document.getElementById('myDiv').offsetHeight || 100
                this.$store.state.headHeight = this.headHeight
                this.isShow = true
            }, 500)
        }
      }
    },
    created() {
        
    },
    mounted() {
        // setTimeout(()=>{
        //     this.headHeight = document.getElementById('myDiv').offsetHeight || 100
        //     this.$store.state.headHeight = this.headHeight
        //     this.isShow = true
        // }, 500)
    },
    methods: {
        
    }
}
</script>
<style scoped lang="scss">
.head_box {
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
}
// .min_h {
//     min-height: calc(100% - 209px) !important;
// }
// .min_h_169 {
//     min-height: calc(100% - 169px) !important;
// }
</style>